<template>
  <div style="display: inline-block">
    <slot name="btn" :scoped="{ showDialog }">
      <el-button type="primary" class="addButton" @click="showDialog">{{ button }}</el-button>
    </slot>
    <AddCountry
      ref="form"
      :dialogVisible.sync="dialogVisible"
      :sup_this="sup_this"
      :is-add="true"
      :title="title"
      v-on="$listeners"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import AddCountry from './module/addCountry'
import { commonEheaderMixin } from '@/mixins'
import { DOMESTIC_FORMWORK } from '@/utils/constant'
export default {
  mixins: [commonEheaderMixin],
  components: { AddCountry },
  computed: {
    button() {
      return this.$route.query.category == DOMESTIC_FORMWORK ? '+ 添加省份 / 地区' : '+ 添加国家 / 地区'
    },
    title() {
      return this.$route.query.category == DOMESTIC_FORMWORK ? '添加省份 / 地区' : '添加国家 / 地区'
    }
  },
  watch: {
    dialogVisible(newVal) {
      if (!newVal) {
        this.$emit('hideAddCountryDialog')
      }
    }
  },
  methods: {
    showDialog() {
      this.dialogVisible = true
      this.$emit('update:selectedCountries', [...this.$attrs.disabledList])
    }
  }
}
</script>

<style lang="scss" socped>
.addButton {
  padding: 0;
  width: 128px;
  height: 32px;
}
</style>
